import axios from 'axios'
import router from '@/router'

const baseURL = process.env.VUE_APP_API_BASE_URL

export const ApiService = axios.create({
    baseURL: baseURL,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
})

ApiService.interceptors.request.use(function (config) {
    // Do something before request is sent
    config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('user-token')
    return config;
}, function (error) {
    // Do something with request error
    if (error.status == 401) {
        console.log("Error in getting api request");
    }
    return Promise.reject(error);
});

// Add a response interceptor
ApiService.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (response.status === 200 || response.status === 201) {
        return Promise.resolve(response);
    } else {
        return Promise.reject(response);
    }
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.status) {
        switch (error.response.status) {
            case 400:
                console.log("Api request not allowed")
                break;

            case 401:
                console.log("Unauthorized")
                localStorage.removeItem("user-token")
                localStorage.removeItem('user')
                setTimeout(() => router.push({ path: '/login' }), 200);
                break;
            case 403:
                localStorage.removeItem("user-token")
                localStorage.removeItem('user')
                router.replace({
                    path: "/login",
                    query: { redirect: router.currentRoute.fullPath }
                });
                break;
            case 404:
                console.log("Not Found")
                break;
            case 502:
                localStorage.removeItem("user-token")
                localStorage.removeItem('user')
                setTimeout(() => {
                    router.replace({
                        path: "/login",
                        query: {
                            redirect: router.currentRoute.fullPath
                        }
                    });
                }, 1000);
        }
        return Promise.reject(error.response);
    }
});
import { ApiService } from "@/services/ApiService"
import { useToast } from 'vue-toastification'

const toast = useToast()

const state = {
    allSuppliers: [],
    refineBatches: [],
    refineBatchesPaginated: null,
    isRefineLoading: false,
    isRefineBatchDelete: false,
}

const getters = {
    allSuppliers: state => state.allSuppliers,
    refineBatches: state => state.refineBatches,
    refineBatchesPaginated: state => state.refineBatchesPaginated,
    isRefineLoading: state => state.isRefineLoading,
    isRefineBatchDelete: state => state.isRefineBatchDelete,
}

const mutations = {
    SET_ALL_SUPPLIERS: (state, allSuppliers) => {
        state.allSuppliers = allSuppliers
    },
    SET_CREATE_SUPPLIER: (state, supplier) => {
        state.allSuppliers.push(supplier)
        //state.createdUserData = user;
    },
    SET_UPDATE_SUPPLIER: (state, supplier) => {
        //state.allSuppliers.push(supplier)
        console.log("---", state, supplier);
    },
    SET_DELETE_SUPPLIER: (state, id) => {
        state.allSuppliers.filter(x => x.id != id);
    },
    GET_REFINE_BATCHES(state, refineBatches) {
        state.refineBatches = refineBatches
    },
    SET_REFINE_BATCHES_PAGINATED(state, refineBatchesPaginated) {
        state.refineBatchesPaginated = refineBatchesPaginated
    },
    SET_LOADING(state, isRefineLoading) {
        state.isRefineLoading = isRefineLoading
    },
    SET_CREATE_BATCH: (state, batch) => {
        state.refineBatches.unshift(batch)
    },
    SET_DELETE_BATCH(state, isRefineBatchDelete) {
        state.isRefineBatchDelete = isRefineBatchDelete
    },
    SET_UPDATE_BATCH_RECORD: (state, record) => {
        console.log("=====", state, record);
        //state.refineBatches.unshift(record)
    },
    SET_DELETE_BATCH_RECORD: (state, record) => {
        console.log("=====", state, record);
        //state.refineBatches.unshift(record)
    },
}

const actions = {
    async getAllSuppliers({ commit }) {
        //commit('SET_LOADING', true);
        await ApiService.get('batch-supplier')
            .then(res => {
                commit('SET_ALL_SUPPLIERS', res.data.data);
                //commit('SET_LOADING', false);
            }).catch(err => {
                console.log('error', err);
                //commit('SET_LOADING', false);
            });
    },

    async createSupplier({ commit }, payload) {
        await ApiService.post('/batch-supplier', payload)
            .then(res => {
                commit('SET_CREATE_SUPPLIER', res.data.data);
                toast.success("Supplier Created Successfully!", { timeout: 2000 })
            }).catch(err => {
                console.log('error', err);
                toast.error("Unable To Add Supplier!", { timeout: 2000 })
            });
    },

    async updateSupplier({ commit }, supplier) {
        await ApiService.put(`/batch-supplier/${supplier.id}`, supplier)
            .then(res => {
                commit('SET_UPDATE_SUPPLIER', res.data.data);
                toast.success("Supplier Updated Successfully!", { timeout: 2000 })
            }).catch(err => {
                console.log('error', err);
                toast.error("Unable To Update Supplier!", { timeout: 2000 })
            });
    },

    async deleteSupplier({ commit }, id) {
        await ApiService.delete(`/batch-supplier/${id}`)
            .then(res => {
                commit('SET_DELETE_SUPPLIER', res.data.data);
                toast.success("Supplier Deleted Successfully!", { timeout: 2000 })
            }).catch(err => {
                console.log('error', err);
                toast.error("Unable To Delete Supplier!", { timeout: 2000 })
            });
    },

    async getRefineBatches({ commit }, { query , id }) {
        let current_page = 1;
        let records = 5
        if (query !== null) {
            current_page = query?.current_page || 1;
            records = query?.records || 5
        }
        commit('SET_LOADING', true);
        let url = '/refine-batch'
        url = `${url}?suplier_id=${id}&current_page=${current_page}&records=${records}`
        await ApiService.get(url)
            .then(res => {
                const batches = res.data.data.data;
                commit('GET_REFINE_BATCHES', batches);
                commit('SET_REFINE_BATCHES_PAGINATED', res.data.data);
                commit('SET_LOADING', false);
            }).catch(err => {
                console.log('error', err);
                commit('SET_LOADING', false);
            });
    },

    async createBatch({ commit }, payload) {
        await ApiService.post('/refine-batch', payload)
            .then(res => {
                commit('SET_CREATE_BATCH', res.data.data);
                toast.success("Batch Created Successfully!", { timeout: 2000 })
            }).catch(err => {
                console.log('error', err);
                toast.error("Unable To Add Batch!", { timeout: 2000 })
            });
    },

    async deleteBatch({ commit }, id) {
        commit('SET_DELETE_BATCH', true);
        await ApiService.delete(`/refine-batch/${id}`)
            .then(res => {
                commit('SET_DELETE_BATCH', res.data.data);
                commit('SET_DELETE_BATCH', false);
                toast.success("Batch Deleted Successfully!", { timeout: 2000 })
            }).catch(err => {
                console.log('error', err);
                commit('SET_DELETE_BATCH', false);
                toast.error("Unable To Delete Batch!", { timeout: 2000 })
            });
    },

    async updateBatchRecord({ commit }, record) {
        await ApiService.put(`/refine-batch/${record.refine_batch}`, record)
            .then(res => {
                commit('SET_UPDATE_BATCH_RECORD', res.data.data);
                toast.success("Batch Record Updated Successfully!", { timeout: 2000 })
            }).catch(err => {
                console.log('error', err);
                toast.error("Unable To Update Batch Record!", { timeout: 2000 })
            });
    },

    async deleteBatchRecord({ commit }, id) {
        await ApiService.delete(`/refine-batch-item/${id}`)
            .then(res => {
                commit('SET_DELETE_BATCH_RECORD', res.data.data);
                //toast.success("Batch Record Deleted Successfully!", { timeout: 2000 })
            }).catch(err => {
                console.log('error', err);
                //toast.error("Unable To Delete Batch Record!", { timeout: 2000 })
            });
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}
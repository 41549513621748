import { ApiService } from "@/services/ApiService"
import { useToast } from 'vue-toastification'

const toast = useToast()

const state = {
    reportListPaginated: null,
    reportList: [],
    isReportLoading: false,
    customReportListPaginated: null,
    isCustomReportLoading: false,
    customReportList: []
}

const getters = {
    reportList: state => state.reportList,
    reportListPaginated: state => state.reportListPaginated,
    isReportLoading: state => state.isReportLoading,
    customReportList: state => state.customReportList,
    customReportListPaginated: state => state.customReportListPaginated,
    isCustomReportLoading: state => state.isCustomReportLoading,
}

const mutations = {
    SET_REPORT_LOADING(state, isReportLoading) {
        state.isReportLoading = isReportLoading
    },
    GET_REPORT_LIST(state, reportList) {
        state.reportList = reportList
    },
    SET_REPORT_LIST_PAGINATED: (state, reportListPaginated) => {
        state.reportListPaginated = reportListPaginated
    },
    SET_CUSTOM_REPORT_LOADING(state, isCustomReportLoading) {
        state.isCustomReportLoading = isCustomReportLoading
    },
    GET_CUSTOM_REPORT_LIST(state, customReportList) {
        state.customReportList = customReportList
    },
    SET_CUSTOM_REPORT_LIST_PAGINATED: (state, customReportListPaginated) => {
        state.customReportListPaginated = customReportListPaginated
    },
}

const actions = {
    async getReports({ commit }, query = null) {
        let current_page = 1;
        let search = '';
        let records = 10
        let type = ''
        let date = ''
        if (query !== null) {
            current_page = query?.current_page || 1;
            search = query?.search || '';
            records = query?.records || 10
            type = query?.type || '';
            date = query?.date || '';
        }
        commit('SET_REPORT_LOADING', true);
        let url = '/reports'
        if (search === null) {
            url = `${url}?current_page=${current_page}`;
        } else {
            url = `${url}?search=${search}&current_page=${current_page}&records=${records}&type=${type}&date=${date}`
        }
        await ApiService.get(url)
            .then(res => {
                const report = res.data.data.data;
                commit('GET_REPORT_LIST', report);
                commit('SET_REPORT_LIST_PAGINATED', res.data.data);
                commit('SET_REPORT_LOADING', false);
            }).catch(err => {
                console.log('error', err);
                commit('SET_REPORT_LOADING', false);
            });
    },

    async getCustomReports({ commit, state }, query = null) {
        let current_page = 1;
        let search = '';
        let records = 10
        let type = 'custom'
        let date = ''
        let start_value = ""
        let end_value = ""
        let customer_type = ""
        let report_type = ""
        let start_date = ""
        let end_date = ""
        if (query !== null) {
            current_page = query?.current_page || 1;
            search = query?.search || '';
            records = query?.records || 10
            type = query?.type || 'custom';
            date = query?.date || '';
            start_value = query?.start_value || ''
            end_value = query?.end_value || ''
            customer_type = query?.customer_type || ''
            report_type = query?.report_type || ''
            start_date = query?.start_date || ''
            end_date = query?.end_date || ''
        }
        commit('SET_CUSTOM_REPORT_LOADING', false);
        let url = '/reports'
        if (search === null) {
            url = `${url}?current_page=${current_page}`;
        } else {
            url = `${url}?search=${search}&current_page=${current_page}&records=${records}&type=${type}&date=${date}&start_value=${start_value}&end_value=${end_value}&customer_type=${customer_type}&report_type=${report_type}&start_date=${start_date}&end_date=${end_date}`
        }
        await ApiService.get(url)
            .then(res => {
                const report = res.data.data.data;
                commit('SET_CUSTOM_REPORT_LIST_PAGINATED', JSON.parse(JSON.stringify(res.data.data)));
                commit('GET_CUSTOM_REPORT_LIST', report);
                console.log(state.customReportListPaginated, res.data.data)
                commit('SET_CUSTOM_REPORT_LOADING', false);
                console.log(state.customReportListPaginated, res.data.data)
                toast.success("Record Found Successfully!", { timeout: 2000 })
            }).catch(err => {
                console.log('error', err);
                commit('SET_CUSTOM_REPORT_LOADING', false);
                toast.error("Unable To Find Record!", { timeout: 2000 })
            });
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}
<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> | 
    <router-link to="/admin">Admin</router-link>
  </nav> -->
  <main class="d-flex flex-column vh-100">
    <AppLayout>
      <router-view />
    </AppLayout>
  </main>
</template>

<script>
import AppLayout from "@/layouts/appLayout.vue";

export default {
  name: "App",
  components: {
    AppLayout,
  },
};
</script>

<style>
body{
  overflow-x: hidden;
}
.gbl__select-entries .gbl__select {
    padding-right: 1.5rem !important;
}
@media print {
  .noPrint{
    display: none;
  }
  #no_print {
    display: none;
  }
}
</style>

import { ApiService } from "@/services/ApiService"
import { useToast } from 'vue-toastification'

const toast = useToast()

const state = {
    allCustomers: [],
    customer: null,
    createdData: null,
    updatedData: null,
    isCustomerCreate: false,
    isCustomerUpdate: false,
    isCustomerDelete: false,
    customersPaginatedData: null,
    isLoading: false,
    customerTransactions: [],
    customerTransactionsPaginated: null,
    customerHistory: [],
    customerHistoryPaginated: null,
    isTransactionDelete: false,
    allTransferCustomers: [],
    customersTransferPaginatedData: null,
}

const getters = {
    allCustomers: state => state.allCustomers,
    customersPaginatedData: state => state.customersPaginatedData,
    customer: state => state.customer,
    isCustomerCreate: state => state.isCustomerCreate,
    isCustomerUpdate: state => state.isCustomerUpdate,
    isCustomerDelete: state => state.isCustomerDelete,
    createdData: state => state.createdData,
    updatedData: state => state.updatedData,
    isLoading: state => state.isLoading,
    customerTransactions: state => state.customerTransactions,
    customerTransactionsPaginated: state => state.customerTransactionsPaginated,
    customerHistory: state => state.customerHistory,
    customerHistoryPaginated: state => state.customerHistoryPaginated,
    isTransactionDelete: state => state.isTransactionDelete,
    allTransferCustomers: state => state.allTransferCustomers,
    customersTransferPaginatedData: state => state.customersTransferPaginatedData,
}

const mutations = {
    GET_ALL_CUSTOMERS(state, allCustomers) {
        state.allCustomers = allCustomers
    },
    SET_CUSTOMER_DETAILS: (state, customer) => {
        state.customer = customer
    },
    SET_CREATE_CUSTOMER: (state, customer) => {
        state.allCustomers.unshift(customer)
        state.createdData = customer;
    },
    SET_UPDATE_CUSTOMER: (state, customer) => {
        state.allCustomers.unshift(customer)
        state.updatedData = customer;
    },
    SET_DELETE_CUSTOMER: (state, id) => {
        state.customersPaginatedData.data.filter(x => x.id !== id);
    },
    SET_CUSTOMER_IS_CREATE(state, isCustomerCreate) {
        state.isCustomerCreate = isCustomerCreate
    },
    SET_CUSTOMER_IS_UPDATE(state, isCustomerUpdate) {
        state.isCustomerUpdate = isCustomerUpdate
    },
    SET_CUSTOMER_IS_DELETE(state, isCustomerDelete) {
        state.isCustomerDelete = isCustomerDelete
    },
    SET_CUSTOMER_PAGINATED: (state, customersPaginatedData) => {
        state.customersPaginatedData = customersPaginatedData
    },
    SET_LOADING(state, isLoading) {
        state.isLoading = isLoading
    },
    GET_CUSTOMER_TRANSACTIONS(state, customerTransactions) {
        state.customerTransactions = customerTransactions
    },
    SET_CUSTOMER_TRANSACTIONS_PAGINATED(state, customerTransactionsPaginated) {
        state.customerTransactionsPaginated = customerTransactionsPaginated
    },
    SET_CREATE_TRANSACTION: (state, transaction) => {
        if (transaction.index) {
            state.customerTransactions.splice(transaction.index + 1, 0, transaction)
        } else {
            state.customerTransactions.unshift(transaction)
        }
        //state.createdData = transaction;
    },
    SET_UPDATE_TRANSACTION: (state, transaction) => {
        state.customerTransactions.unshift(transaction)
        //state.updatedData = transaction;
    },
    SET_DELETE_TRANSACTION: (state, id) => {
        state.customerTransactionsPaginated.data.filter(x => x.id !== id);
    },
    GET_CUSTOMER_HISTORY(state, customerHistory) {
        state.customerHistory = customerHistory
    },
    SET_CUSTOMER_HISTORY_PAGINATED(state, customerHistoryPaginated) {
        state.customerHistoryPaginated = customerHistoryPaginated
    },
    SET_TRANSACTION_IS_DELETE(state, isTransactionDelete) {
        state.isTransactionDelete = isTransactionDelete
    },
    GET_ALL_TRANSFER_CUSTOMERS(state, allTransferCustomers) {
        state.allTransferCustomers = allTransferCustomers
    },
    SET_TRANSFER_CUSTOMER_PAGINATED: (state, customersTransferPaginatedData) => {
        state.customersTransferPaginatedData = customersTransferPaginatedData
    },
}

const actions = {
    async getAllCustomers({ commit }, query = null) {
        let current_page = 1;
        let search = '';
        let records = 10
        if (query !== null) {
            current_page = query?.current_page || 1;
            search = query?.search || '';
            records = query?.records || 10
        }
        commit('SET_LOADING', true);
        let url = '/customers'
        if (search === null) {
            url = `${url}?current_page=${current_page}`;
        } else {
            url = `${url}?search=${search}&current_page=${current_page}&records=${records}`
        }
        await ApiService.get(url)
            .then(res => {
                const customers = res.data.data.data;
                commit('GET_ALL_CUSTOMERS', customers);
                commit('SET_CUSTOMER_PAGINATED', res.data.data);
                commit('SET_LOADING', false);
            }).catch(err => {
                console.log('error', err);
                commit('SET_LOADING', false);
            });
    },
    async fetchCustomerDetails({ commit }, id) {
        //commit('SET_LOADING', true);
        await ApiService.get(`customers/${id}`)
            .then(res => {
                commit('SET_CUSTOMER_DETAILS', res.data.data);
                //commit('SET_LOADING', false);
            }).catch(err => {
                console.log('error', err);
                //commit('SET_LOADING', false);
            });
    },
    async createCustomer({ commit }, payload) {
        commit('SET_CUSTOMER_IS_CREATE', true);
        await ApiService.post('/customers', payload)
            .then(res => {
                commit('SET_CREATE_CUSTOMER', res.data.data);
                commit('SET_CUSTOMER_IS_CREATE', false);
                toast.success("Customer Created Successfully!", { timeout: 2000 })
            }).catch(err => {
                console.log('error', err);
                commit('SET_CUSTOMER_IS_CREATE', false);
                toast.error("Unable To Add Customer!", { timeout: 2000 })
            });
    },
    async updateCustomer({ commit }, customer) {
        commit('SET_CUSTOMER_IS_UPDATE', true);
        return await ApiService.put(`/customers/${customer.id}`, customer)
            .then(res => {
                commit('SET_UPDATE_CUSTOMER', res.data.data);
                commit('SET_CUSTOMER_IS_UPDATE', false);
                toast.success("Customer Updated Successfully!", { timeout: 2000 })
                return res.data.data;
            }).catch(err => {
                console.log('error', err);
                commit('SET_CUSTOMER_IS_UPDATE', false);
                toast.error("Unable To Update Customer!", { timeout: 2000 })
            });
    },
    async deleteCustomer({ commit }, id) {
        commit('SET_CUSTOMER_IS_DELETE', true);
        await ApiService.delete(`/customers/${id}`)
            .then(res => {
                commit('SET_DELETE_CUSTOMER', res.data.data.id);
                commit('SET_CUSTOMER_IS_DELETE', false);
                toast.success("Customer Deleted Successfully!", { timeout: 2000 })
            }).catch(err => {
                console.log('error', err);
                commit('SET_CUSTOMER_IS_DELETE', false);
                toast.error("Unable To Delete Customer!", { timeout: 2000 })
            });
    },

    // Customer Transactions
    async getCustomerTransactions({ commit }, { query = null, id }) {
        let current_page = 1;
        let search = '';
        let records = 50
        if (query !== null) {
            current_page = query?.current_page || 1;
            search = query?.search || '';
            records = query?.records || 50
        }
        commit('SET_LOADING', true);
        let url = '/transactions'
        if (search === null) {
            url = `${url}?customer_id=${id}&current_page=${current_page}`;
        } else {
            url = `${url}?customer_id=${id}&search=${search}&current_page=${current_page}&records=${records}`
        }
        await ApiService.get(url)
            .then(res => {
                const transactions = res.data.data.data;
                commit('GET_CUSTOMER_TRANSACTIONS', transactions);
                commit('SET_CUSTOMER_TRANSACTIONS_PAGINATED', res.data.data);
                commit('SET_LOADING', false);
            }).catch(err => {
                console.log('error', err);
                commit('SET_LOADING', false);
            });
    },

    async createTransaction({ commit }, payload) {
        //commit('SET_CUSTOMER_IS_CREATE', true);
        return await ApiService.post('/transactions', payload)
            .then(res => {
                commit('SET_CREATE_TRANSACTION', { ...res.data.data, index: payload.index });
                //commit('SET_CUSTOMER_IS_CREATE', false);
                toast.success("Transaction Created Successfully!", { timeout: 2000 })
                return res.data.data
            }).catch(err => {
                console.log('error', err);
                //commit('SET_CUSTOMER_IS_CREATE', false);
                toast.error("Unable To Add Transaction!", { timeout: 2000 })
            });
    },

    async updateTransaction({ commit }, transaction) {
        //commit('SET_CUSTOMER_IS_UPDATE', true);
        return await ApiService.put(`/transactions/${transaction.id}`, transaction)
            .then(res => {
                commit('SET_UPDATE_TRANSACTION', res.data.data);
                //commit('SET_CUSTOMER_IS_UPDATE', false);
                toast.success("Transaction Updated Successfully!", { timeout: 2000 })
                return res.data.data
            }).catch(err => {
                console.log('error', err);
                //commit('SET_CUSTOMER_IS_UPDATE', false);
                toast.error("Unable To Update Transaction!", { timeout: 2000 })
            });
    },

    async deleteTransaction({ commit }, id) {
        commit('SET_TRANSACTION_IS_DELETE', true);
        await ApiService.delete(`/transactions/${id}`)
            .then(res => {
                commit('SET_DELETE_TRANSACTION', res.data.data.id);
                commit('SET_TRANSACTION_IS_DELETE', false);
                toast.success("Transaction Deleted Successfully!", { timeout: 2000 })
            }).catch(err => {
                console.log('error', err);
                commit('SET_TRANSACTION_IS_DELETE', false);
                toast.error("Unable To Delete Transaction!", { timeout: 2000 })
            });
    },

    // Customer History
    async getCustomerHistory({ commit }, { query = null, id }) {
        let current_page = 1;
        let search = '';
        let records = 10
        if (query !== null) {
            current_page = query?.current_page || 1;
            search = query?.search || '';
            records = query?.records || 10
        }
        commit('SET_LOADING', true);
        let url = '/transactions-history'
        if (search === null) {
            url = `${url}?customer_id=${id}&current_page=${current_page}`;
        } else {
            url = `${url}?customer_id=${id}&search=${search}&current_page=${current_page}&records=${records}`
        }
        await ApiService.get(url)
            .then(res => {
                const history = res.data.data.data;
                commit('GET_CUSTOMER_HISTORY', history);
                commit('SET_CUSTOMER_HISTORY_PAGINATED', res.data.data);
                commit('SET_LOADING', false);
            }).catch(err => {
                console.log('error', err);
                commit('SET_LOADING', false);
            });
    },
    async getAllTransferCustomer({ commit }, query = null) {
        let current_page = 1;
        let search = '';
        let records = 10
        if (query !== null) {
            current_page = query?.current_page || 1;
            search = query?.search || '';
            records = query?.records || 10
        }
        //commit('SET_LOADING', true);
        let url = '/customers'
        if (search === null) {
            url = `${url}?current_page=${current_page}`;
        } else {
            url = `${url}?search=${search}&current_page=${current_page}&records=${records}`
        }
        await ApiService.get(url)
            .then(res => {
                const customers = res.data.data.data;
                commit('GET_ALL_TRANSFER_CUSTOMERS', customers);
                commit('SET_TRANSFER_CUSTOMER_PAGINATED', res.data.data);
            }).catch(err => {
                console.log('error', err);
            });
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}
import { ApiService } from "@/services/ApiService"

const state = {
    isLoading: false,
    allSearchTransaction: [],
    searchTransactionPaginated: null,
};

const getters = {
    isLoading: state => state.isLoading,
    allSearchTransaction: state => state.allSearchTransaction,
    searchTransactionPaginated: state => state.searchTransactionPaginated,
};

const mutations = {
    SET_LOADING(state, isLoading) {
        state.isLoading = isLoading
    },
    GET_ALL_TRANSACTIONS(state, allSearchTransaction) {
        state.allSearchTransaction = allSearchTransaction
    },
    SET_TRANSACTION_PAGINATED: (state, searchTransactionPaginated) => {
        state.searchTransactionPaginated = searchTransactionPaginated
    },
};

const actions = {

    async getAllSearchTransactions({ commit }, query = null) {
        let current_page = 1;
        let search = '';
        let records = 100
        if (query !== null) {
            current_page = query?.current_page || 1;
            search = query?.search || '';
            records = query?.records || 100
        }
        commit('SET_LOADING', true);
        let url = 'transactions-search'
        if (search === null) {
            url = `${url}?current_page=${current_page}`;
        } else {
            url = `${url}?search=${search}&current_page=${current_page}&records=${records}`
        }
        await ApiService.get(url)
            .then(res => {
                const dashboard_trans = res.data.data.data;
                commit('GET_ALL_TRANSACTIONS', dashboard_trans);
                commit('SET_TRANSACTION_PAGINATED', res.data.data);
                commit('SET_LOADING', false);
            }).catch(err => {
                console.log('error', err);
                commit('SET_LOADING', false);
            });
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
}
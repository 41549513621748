import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",  // dashboard
    name: "DashBoard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/pages/dashboard.vue"),
    meta: {
      layout: "default",
      requiresAuth: true
    },
  },
  {
    name: "CustomerTab", //customer
    path: "/customers",
    component: () => import("@/pages/customer.vue"),
    meta: {
      layout: "default",
      requiresAuth: true
    },
  },
  {
    name: "CustomerDetails", //customer
    path: "/customer/:id",
    component: () => import("@/components/customer/CustomerDetails.vue"),
    meta: {
      layout: "default",
      requiresAuth: true
    },
  },
  {
    name: "CustomerHistory", //customer history
    path: "/customer-history/:id",
    component: () => import("@/components/customer/CustomerHistory.vue"),
    meta: {
      layout: "default",
      requiresAuth: true
    },
  },
  {
    name: "ReportsTab", //reports
    path: "/reports",
    component: () => import("@/pages/reports.vue"),
    meta: {
      layout: "default",
      requiresAuth: true
    },
  },
  {
    name: "CustomReport", //custom reports
    path: "/reports/custom",
    component: () => import("@/components/reports/CustomReport/CustomReport.vue"),
    meta: {
      layout: "default",
      requiresAuth: true
    },
  },
  {
    name: "UsersTab", //users
    path: "/users",
    component: () => import("@/pages/users.vue"),
    meta: {
      layout: "default",
      requiresAuth: true
    },
  },
  {
    name: "UserProfile", //customer
    path: "/user/:id",
    component: () => import("@/components/user/UserProfile.vue"),
    meta: {
      layout: "default",
      requiresAuth: true
    },
  },
  {
    name: "Refine", //refine
    path: "/refine",
    component: () => import("@/pages/refine.vue"),
    meta: {
      layout: "default",
      requiresAuth: true
    },
  },
  {
    name: "Search", //refine
    path: "/search",
    component: () => import("@/pages/search.vue"),
    meta: {
      layout: "default",
      requiresAuth: true
    }
  },
  {
    name: "NotificationsTab", //notifications
    path: "/notifications",
    component: () => import("@/pages/notifications.vue"),
    meta: {
      layout: "default",
      requiresAuth: true
    },
  },
  {
    name: "NotificationList", //notifications
    path: "/notifications/:id",
    component: () => import("@/components/notification/NotificationList.vue"),
    meta: {
      layout: "default",
      requiresAuth: true
    },
  },
  {
    name: "ProfileTab", //profile
    path: "/profile",
    component: () => import("@/pages/profile.vue"),
    meta: {
      layout: "default",
      requiresAuth: true
    },
  },
  {
    name: "LoginPage", // login
    path: "/login",
    component: () => import("@/components/Login.vue"),
    meta: {
      layout: "basic",
      requiresAuth: false
    },
  },
  {
    name: "RegisterPage", // register
    path: "/register",
    component: () => import("@/components/Register.vue"),
    meta: {
      layout: "basic",
    },
  }, 
  {
    name: "ResetPassword", // reset password
    path: "/reset_password",
    component: () => import("@/components/ResetPassword.vue"),
    meta: {
      layout: "basic",
      requiresAuth: false
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', "/reset_password"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user-token');
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});

export default router;

import { ApiService } from "@/services/ApiService"
import router from '@/router'
import { useToast } from 'vue-toastification'

const toast = useToast()

const state = {
    user: {},
    userToken: '',
    isAuthLoader: false

}

const getters = {
    currentUser: (state) => {
        return state.user
    },
    userToken: state => state.userToken,
    loggedInUser: state => state.user,
    isAuthLoader: state => state.isAuthLoader

}

const mutations = {
    SET_USER_TOKEN(state, payload) {
        state.userToken = payload;
    },
    SET_CURRENT_USER_DETAILS(state, payload) {
        state.user = payload;
    },
    SET_AUTH_LOADER(state, payload) {
        state.isAuthLoader = payload
    }

}

const actions = {
    async login({ commit }, payload) {
        commit('SET_AUTH_LOADER', true)
        await ApiService.post("/login", payload)
            .then((response) => {
                if (response.status === 200) {
                    let responseData = response.data.data
                    const token = responseData.token
                    let user = responseData.user
                    localStorage.setItem("user-token", token);
                    localStorage.setItem("user", JSON.stringify(user));
                    localStorage.setItem("user-permissions", JSON.stringify(responseData.permissions));
                    commit('SET_AUTH_LOADER', false)
                    commit("SET_USER_TOKEN", token);
                    commit("SET_CURRENT_USER_DETAILS", user);
                    toast.success("Logged in Successfully!", { timeout: 2000 })
                    setTimeout(() => router.push({ path: '/' }), 200);
                }
            })
            .catch((error) => {
                console.log(error)
                commit('SET_AUTH_LOADER', false)
                if(error.status == "401"){
                    toast.error("InValid credentials", { timeout: 2000 })
                }
                else{
                toast.error("Unable to login!", { timeout: 2000 })
                }
            })
    },

    async logout({ commit }) {
        await ApiService.post("/logout")
            .then(() => {
                localStorage.removeItem("user-token")
                localStorage.removeItem('user')
                localStorage.removeItem('user-permissions')
                commit("SET_USER_TOKEN", null);
                commit("SET_CURRENT_USER_DETAILS", null);
                toast.success("Logged Out Successfully!", { timeout: 1000 })
                setTimeout(() => router.push({ path: '/login' }), 200);
            })
            .catch((error) => {
                console.log(error)
            })

    },

    async resetPassword({ commit }, payload) {
        commit('SET_AUTH_LOADER', true)
        await ApiService.post("/forget-password", payload)
            .then((response) => {
                if (response.status === 200) {
                    commit('SET_AUTH_LOADER', false)
                    toast.success("Password reset link sent on your email!", { timeout: 1000 })
                }
            })
            .catch((error) => {
                console.log(error)
                commit('SET_AUTH_LOADER', false)
                toast.error("Error in sending email", { timeout: 1000 })
            })
    },

    async changePassword({ commit }, payload) {
        commit('SET_AUTH_LOADER', true)
        await ApiService.post("/change-password", payload)
            .then((response) => {
                if (response.status === 200) {
                    commit('SET_AUTH_LOADER', false)
                    toast.success("Password changed successfully!", { timeout: 1000 })
                }
            })
            .catch((error) => {
                console.log(error)
                commit('SET_AUTH_LOADER', false)
                toast.error("Unable to change password!", { timeout: 1000 })
            })
    },
}


export default {
    state,
    getters,
    actions,
    mutations,
};
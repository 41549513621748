<template>
  <div class="dropdown gbl__dropdownbox">
    <button class="
        d-flex
        align-items-center
        gbl__dropdown-btn gbl__dropdown--light
        dropdown-toggle
      " type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
      <span class="gbl__dropdown-iconspan"><img src="@/assets/images/notification.svg" alt="user_img" /></span>
    </button>
    <div class="
        dropdown-menu
        gbl__dropdown-menu
        gbl__dropdown-menu--space
        gbl__dropdown-menu
        gbl__dropdown-menu--large
        dropdown-menu-end
      ">
      <div class="gbl__dropdown-wrapper">
        <div class="gbl__dropdown-menu-box">
          <h2 class="gbl__dropdown-menu-heading">Notification</h2>
          <ul class="nav nav-pills" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
              <button class="gbl__tab-btn btn btn-sm active" id="pills-general-tab" data-bs-toggle="pill"
                data-bs-target="#pills-general" type="button" role="tab" aria-controls="pills-general"
                aria-selected="true" @click="NotificationButton('general')">
                General
              </button>
            </li>
            <li class="nav-item ms-3" role="presentation">
              <button class="gbl__tab-btn btn btn-sm" id="pills-alert-tab" data-bs-toggle="pill"
                data-bs-target="#pills-alert" type="button" role="tab" aria-controls="pills-alert" aria-selected="false"
                @click="NotificationButton('alert')">
                Alerts
              </button>
            </li>
          </ul>
        </div>
        <div class="gbl__tab-menubox">
          <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-general" role="tabpanel" aria-labelledby="pills-general-tab"
              tabindex="0">
              <div class="gbl__tab-wrapper">
                <ul class="gbl__tab-list">
                  <li class="d-flex gbl__tab-item" v-for="item in generalNotificationData" :key="item.key"
                    :value="item.value">
                    <span class="
                        d-flex
                        align-items-center
                        justify-content-center
                        gbl__tab-iconmenu gbl__tab-iconmenu--gold
                        rounded-circle
                      ">
                      <img v-if="item.subject == 'User' || item.subject == 'Auth'" src="@/assets/images/user.svg"
                        alt="pic" />

                      <img v-else-if="item.subject == 'Refine' || item.subject == 'Supplier'"
                        src="@/assets/images/coin-stack.svg" alt="pic" />

                      <img v-else src="@/assets/images/bx-transfer-alt.svg" alt="pic" />
                    </span>
                    <p class="gbl__tab-text">
                      {{ item.note }}
                    </p>
                  </li>
                </ul>
                <div class="text-center mb-2">
                  <button class="gbl__tab-btn-text gbl__tab-btn-text--gold" @click="viewNotifications"
                    data-bs-dismiss="dropdown">
                    View All
                  </button>
                </div>
              </div>

            </div>
            <div class="tab-pane fade" id="pills-alert" role="tabpanel" aria-labelledby="pills-alert-tab" tabindex="0">
              <div>
                <div class="gbl__tab-wrapper">
                  <ul class="gbl__tab-list">
                    <li class="d-flex gbl__tab-item" v-for="item in notificationData" :key="item.key" :value="item.value">
                      <span class="
                        d-flex
                        align-items-center
                        justify-content-center
                        gbl__tab-iconmenu gbl__tab-iconmenu--gold
                        rounded-circle
                      ">
                        <img src="@/assets/images/coin-stack.svg" alt="notification__list" />
                      </span>
                      <p class="gbl__tab-text">
                        {{ item.message }}
                      </p>
                    </li>
                  </ul>
                  <div class="text-center">
                    <button class="gbl__tab-btn-text gbl__tab-btn-text--gold" data-bs-dismiss="dropdown"
                      @click="viewNotifications(selectedTab)">
                      View All
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { computed, ref, onMounted } from 'vue'

const store = useStore();
const router = useRouter();
const selectedTab = ref('general');


onMounted(() => {
  bellNotifications();
  bellAlertNotification();
});

// methods

const NotificationButton = (notificationType) => {
  selectedTab.value = notificationType
}

const viewNotifications = (notificationType) => {
  if (notificationType == 'general') {
    selectedTab.value = notificationType
    router.push({ path: '/notifications' })
    store.dispatch("getAllGeneralNotifications")
  } else {
    selectedTab.value = notificationType
    router.push({ path: '/notifications' })
    store.dispatch("getAllNotifications")
  }
}

const bellNotifications = () => {
  store.dispatch("getAllGeneralNotifications")
}
const bellAlertNotification = () => {
  store.dispatch("getAllNotifications")
}


//computed

const notificationData = computed(() => {
  return store.getters.getNotifications.filter((arr, index) => {
    return index < 3
  });
});

const generalNotificationData = computed(() => {
  return store.getters.getGenernalNotification.filter((arr, index) => {
    return index < 3
  });
});


</script>

<style></style>
import { createStore } from "vuex";
import Auth from "./modules/auth"
import Customer from "./modules/customer"
import Users from "./modules/users"
import DashBoard from "./modules/dashboard"
import Refine from "./modules/refine"
import Report from "./modules/report"
import search from "./modules/search"
import notification from "./modules/notification"

export default createStore({
  modules: {
    Auth,
    DashBoard,
    Customer,
    Users,
    Refine,
    Report,
    search,
    notification
  },
});

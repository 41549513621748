import { ApiService } from "@/services/ApiService"

const state = {
    isDashboardLoading: false,
    allTransactions: [],
    dashboardTransactionPaginated: null,
};

const getters = {
    isDashboardLoading: state => state.isDashboardLoading,
    allTransactions: state => state.allTransactions,
    dashboardTransactionPaginated: state => state.dashboardTransactionPaginated,
};

const mutations = {
    SET_DASHBOARD_LOADING(state, isDashboardLoading) {
        state.isDashboardLoading = isDashboardLoading
    },
    GET_ALL_TRANSACTIONS(state, allTransactions) {
        state.allTransactions = allTransactions
    },
    SET_TRANSACTION_PAGINATED: (state, dashboardTransactionPaginated) => {
        state.dashboardTransactionPaginated = dashboardTransactionPaginated
    },
};

const actions = {

    async getAllDashboardTransactions({ commit }, query = null) {
        let current_page = 1;
        let search = '';
        let records = 50
        if (query !== null) {
            current_page = query?.current_page || 1;
            search = query?.search || '';
            records = query?.records || 50
        }
        commit('SET_DASHBOARD_LOADING', true);
        let url = '/dashboard-transactions'
        if (search === null) {
            url = `${url}?current_page=${current_page}`;
        } else {
            url = `${url}?search=${search}&current_page=${current_page}&records=${records}`
        }
        await ApiService.get(url)
            .then(res => {
                const dashboard_trans = res.data.data.data;
                commit('GET_ALL_TRANSACTIONS', dashboard_trans);
                commit('SET_TRANSACTION_PAGINATED', res.data.data);
                commit('SET_DASHBOARD_LOADING', false);
            }).catch(err => {
                console.log('error', err);
                commit('SET_DASHBOARD_LOADING', false);
            });
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
}
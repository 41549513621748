import { ApiService } from "@/services/ApiService"

const state = {
    allNotification: [],
    allGeneralNotification:[],
    isNotificationLoading: false,
    notificationPaginated: null,
    notification: null,
    generalNotification: null,
}

const getters = {
    getNotifications: state => state.allNotification,
    notificationPaginated: state => state.notificationPaginated,
    isNotificationLoading: state => state.isNotificationLoading,
    notification: state => state.notification,
    getGenernalNotification: state => state.allGeneralNotification,
}

const mutations = {
    GET_ALL_NOTIFICATIONS(state, allNotification) {
        state.allNotification = allNotification
    },
    SET_NOTIFICATION_PAGINATED: (state, notificationPaginated) => {
        state.notificationPaginated = notificationPaginated
    },
    SET_LOADING(state, isNotificationLoading) {
        state.isNotificationLoading = isNotificationLoading
    },
    SET_NOTIFICATIONS_DETAILS: (state, notification) => {
        state.allNotification = notification
    },
    GET_ALL_GENERAL_NOTIFICATIONS(state, allGeneralNotification){
         state.allGeneralNotification = allGeneralNotification
    },
    SET_GENERAL_NOTIFICATIONS_DETAILS: (state, generalNotification) => {
        state.allGeneralNotification = generalNotification
    },
}

// Alert
const actions = {
    async getAllNotifications({ commit }) {
        commit('SET_LOADING', true);
        let url = '/balance-alert'
        await ApiService.get(url)
            .then(res => {
                const notifications = res.data.data.data;
                commit('GET_ALL_NOTIFICATIONS', notifications);
                commit('SET_NOTIFICATIONS_DETAILS', res.data.data);
                commit('SET_LOADING', false);
            }).catch(err => {
                console.log('error', err);
                commit('SET_LOADING', false);
            });
    },

//general
    async getAllGeneralNotifications({ commit }) {
        commit('SET_LOADING', true);
        let url = '/general-alert'
        await ApiService.get(url)
            .then(res => {
                const notifications = res.data.data.data;
                commit('GET_ALL_GENERAL_NOTIFICATIONS', notifications);
                commit('SET_GENERAL_NOTIFICATIONS_DETAILS', res.data.data);
                commit('SET_LOADING', false);
            }).catch(err => {
                console.log('error', err);
                commit('SET_LOADING', false);
            });
    },

}
export default {
    state,
    getters,
    mutations,
    actions
}